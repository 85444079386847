@import '~@angular/material/theming';
@import 'eve.theme.colors';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Create the dark and light theme objects (a Sass map containing all of the palettes).
$dark-theme: mat-dark-theme($color-palette-primary, $color-palette-accent, $color-palette-warn);
$light-theme: mat-light-theme($color-palette-primary, $color-palette-accent, $color-palette-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($dark-theme);

// Apply light theme to dedicated components
@include mat-menu-theme($light-theme);
@include mat-divider-theme($light-theme);
@include mat-dialog-theme($light-theme);
@include mat-table-theme($light-theme);
@include mat-list-theme($light-theme);

.light-theme-input {
  @include mat-form-field-theme($light-theme);
  @include mat-input-theme($light-theme);
  @include mat-select-theme($light-theme);
  @include mat-radio-theme($light-theme);

  .mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.42) !important;
  }
}
