/* You can add global styles to this file, and also import other style files */

/*@import "~@angular/material/prebuilt-themes/indigo-pink.css";*/

@import 'src/app/eve.theme.scss';
@import 'src/app/eve.theme.extensions.scss';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  text-align: initial !important; // Needed for RTL and LTR
}

html {
  // background-color: #008cff;
  // background-image: url('assets/background.svg');
  // background-size: cover;
  // background-attachment: fixed;
}

.h-100 {
  height: 100%;
}

.text-subtle {
  color: rgba(255, 255, 255, 0.7) !important;
}

.text-subtle-light {
  color: rgba(0, 0, 0, 0.5) !important;
}

.warning-block {
  background-color: rgba(0, 0, 0, 0.2) !important;
  padding: 0.75em 1em;
}

// Default Margins and paddings
.content-card-container {
  padding-top: 2rem;
  padding-bottom: 3rem;

  @include media-breakpoint-down(sm) {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
}

.content-card {
  margin-top: 1rem;
}

.content-card-actions {
  margin-top: 1rem;
}

// Fix some Bootstrap CSS stuff
$link-hover-decoration: none;
$link-hover-color: none;
@import 'node_modules/bootstrap/scss/reboot';
@import 'node_modules/bootstrap/scss/grid';
@import 'node_modules/bootstrap/scss/utilities';
*:focus {
  outline: none !important;
}

// Hide Scrollbars
.hidescrollbar {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.hidescrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.falarge {
  font-size: large;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.single-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.spin {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

.spin-slow {
  -webkit-animation: spin 20s linear infinite;
  -moz-animation: spin 20s linear infinite;
  animation: spin 20s linear infinite;
}

.spin-fast {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.card-container {
  margin-bottom: 30px;

  .event-card {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.event-name {
  font-size: 1.5rem;
  text-align: center;
}

.event-card-feature {
  margin: 0 0.3em;
}

// List view specific styles
@mixin list() {
  .card-container {
    margin-bottom: 10px;
  }

  .add-icon {
    width: 32px;
    height: 32px;
    font-size: 32px;
  }

  .event-card {
    flex-direction: row;
  }

  .event-name {
    order: -1;
    flex-grow: 2;
    text-align: left;
  }
}

code {
  display: block;
  background-color: #282a36;
  color: white;
  padding: 0.5em;
  word-break: break-all;
}

.pointer {
  cursor: pointer;
}

// List view specific styles
.list {
  @include list();
}

// Always use list style on small devices
@include media-breakpoint-down(sm) {
  .list {
    @include list();
  }
  .grid {
    @include list();
  }
}

.gap-1 {
  gap: 0.5rem;
}
