// Remove corner radius and shadows
.mat-card,
.mat-button,
.mat-flat-button,
.mat-stroked-button,
.mat-menu-panel,
.mat-select-panel,
.mat-button-toggle-group {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.mat-button-base,
.mat-button {
  white-space: normal !important;
}

.mat-stroked-button {
  border-width: 2px !important;
}

.mat-stroked-button:not([disabled]) {
  border-color: rgba(255, 255, 255, 0.5) !important;
}

.mat-fab {
  box-shadow: none !important;
}

.mat-button-toggle-appearance-standard {
  background: none !important;
}

.mat-button-toggle-checked {
  background: rgba(0, 0, 0, 0.3) !important;
}

.mat-card {
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: none !important;
}

.mat-app-background {
  background-color: transparent !important;
}

.mat-tab-label-active {
  opacity: 1 !important;
}

// Hover
$card-hover-color: rgba(0, 0, 0, 0.3);

@mixin hover-animation() {
  -o-transition: background 0.5s;
  -ms-transition: background 0.5s;
  -moz-transition: background 0.5s;
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}

@mixin smooth-animation() {
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
